import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AppComponentActions,
  AppEffectsActions,
  LanguageGuardActions,
  MyProfileContainerActions,
} from '../actions';
import { AppState } from './app.state';

export const initialState: Readonly<AppState> = {
  language: 'de',
  loggedIn: false,
  customer: null,
  events: null,
  internalLabels: null,
  deleteLabelId: null,
  authActive: false,
  qrCode: null,
  authError: false,
  authCorrect: false,
  subscription: null,
  showCancelSubscriptionDialog: false,
  showReactivateSubscriptionDialog: false,
  showDeleteProfileDialog: false,
  roles: null,
};

export const appReducers = createReducer(
  initialState,
  on(AppEffectsActions.customerDataObtained, (state, { customer, roles }) => ({
    ...state,
    customer: customer,
    roles,
  })),
  on(
    MyProfileContainerActions.saveChangesProfileClickedWasSuccessful,
    (state, { updateResponse }) => ({
      ...state,
      customer: updateResponse,
    }),
  ),
  on(MyProfileContainerActions.deleteLabelClicked, (state, { labelId }) => ({
    ...state,
    deleteLabelId: labelId,
  })),
  on(MyProfileContainerActions.cancelOrbitClicked, (state) => ({
    ...state,
    showCancelSubscriptionDialog: true,
  })),
  on(MyProfileContainerActions.deleteProfileClicked, (state) => ({
    ...state,
    showDeleteProfileDialog: true,
  })),
  on(
    MyProfileContainerActions.deleteProfileAbortClicked,
    MyProfileContainerActions.deleteProfileSubmitClicked,
    (state) => ({
      ...state,
      showDeleteProfileDialog: false,
    }),
  ),
  on(
    MyProfileContainerActions.cancelOrbitAbortClicked,
    MyProfileContainerActions.cancelOrbitSubmitClicked,
    (state) => ({
      ...state,
      showCancelSubscriptionDialog: false,
    }),
  ),
  on(MyProfileContainerActions.reactivateOrbitClicked, (state) => ({
    ...state,
    showReactivateSubscriptionDialog: true,
  })),
  on(
    MyProfileContainerActions.reactivateOrbitAbortClicked,
    MyProfileContainerActions.reactivateOrbitSubmitClicked,
    (state) => ({
      ...state,
      showReactivateSubscriptionDialog: false,
    }),
  ),
  on(
    MyProfileContainerActions.deleteLabelAbortClicked,
    AppEffectsActions.deleteLabelSuccessful,
    AppEffectsActions.deleteLabelFailed,
    (state) => ({
      ...state,
      deleteLabelId: null,
    }),
  ),
  on(AppEffectsActions.twoAuthCorrect, (state, { loginResponse }) => ({
    ...state,
    authCorrect: true,
    loggedIn: true,
    authActive: false,
  })),
  on(AppEffectsActions.twoAuthNotCorrect, (state) => ({
    ...state,
    authError: true,
  })),
  on(AppEffectsActions.setAuthClean, (state) => ({
    ...state,
    authError: false,
  })),
  on(AppEffectsActions.twoFACodeGenerated, (state, { qrCode }) => ({
    ...state,
    qrCode,
    authActive: true,
  })),
  on(AppEffectsActions.twoFactorActive, (state) => ({
    ...state,
    authActive: true,
  })),
  on(AppComponentActions.logoutClicked, (state) => ({
    ...state,
    loggedIn: false,
    events: null,
  })),
  on(AppEffectsActions.sessionLoginIsValid, (state, { loginResponse }) => ({
    ...state,
    loggedIn: true,
  })),
  on(
    LanguageGuardActions.languageChanged,
    AppComponentActions.languageClicked,
    (state, { language }) => ({
      ...state,
      language,
    }),
  ),
  on(
    AppEffectsActions.loggedIn,
    AppEffectsActions.reloadCustomerDataSuccess,
    (state, { customerData }) => ({
      ...state,
      events: customerData.events,
      internalLabels: customerData.internalLabel,
      subscription: customerData.subscription,
    }),
  ),
);

export const appFeature = createFeature({
  name: 'app',
  reducer: appReducers,
});
